import React from "react";
import styled from "styled-components";
import {graphql, Link} from "gatsby";
import Layout from "../../components/Layout";
import GraphQLErrorList from "../../components/GraphQLErrorList";
import BlogPostsList from "../../components/BlogPostsList";

const Root = styled.div`
  .pagination {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a {
      font-size: 22px;
    }
  }
`;

export const query = graphql`
  query archiveBlogsQuery {
    posts: allSanityPost(sort: { fields: _createdAt, order: DESC }, skip: 10) {
      edges {
        node {
          id
          title
          excerpt
          mainImage: _rawMainImage
          slug: _rawSlug
          body: _rawBody(resolveReferences: { maxDepth: 10 })
        }
      }
      totalCount
    }
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
    footer: sanitySiteFooter {
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Blogs = ({ data, errors }) => {
  const posts = data.posts.edges.map((i) => i.node);

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const { settings, footer } = data;

  return (
    <Layout
      footer={footer}
      settings={settings}
      title="Text In Church Blog"
      description="The Text In Church Blog provides articles, trainings, and free resources for pastors, communications directors, hospitality teams and other church leaders designed specifically to connect with your community and build relationships"
    >
      <Root>
        <div className="section-spacer">
          <div className="row">
            <div className="col-xs-18 col-md-16 col-md-offset-1 align-justify">
              <h1 className="xl text-blue">
                Communication Resources For Church Leaders
              </h1>
            </div>
          </div>
        </div>
        <div className="blog-spacer">
          <BlogPostsList posts={posts} />
          {!posts.length ? (
            <div className="row">
              <div className="col-xs-18 col-md-offset-1 col-sm-6 col-md-6" />
              <div className="col-xs-14 col-sm-12 col-md-10">
                <h4>No matching blog found.</h4>
              </div>
            </div>
          ) : null}
        </div>
        <div className="pagination row">
          <div className="col-xs-14 col-sm-12 col-md-10 col-md-offset-7">
            <Link to="/blog">New Posts</Link>
          </div>
        </div>
      </Root>
    </Layout>
  );
};

export default Blogs;
